import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import store, { history } from './store';
import App from './containers/app';
import ScrollToTop from './components/scroll_to_top';

import 'sanitize.css/sanitize.css';
import './styles/tailwind.css';
import init from './initializer';

const target = document.querySelector('#root')

init();

render(
  <Provider store={store}>
    <ConnectedRouter history={history} onUpdate={() => window.scrollTo(0,0)}>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </ConnectedRouter>
  </Provider>,
  target
)
