import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Card from 'components/style/Card';
import PlaceholderCard from 'components/style/PlaceholderCard';

const CoursesOverview = ({ dispatch, courses }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 mb-5">

      {
        courses.length === 0 &&
        <>
          <PlaceholderCard />
          <PlaceholderCard />
        </>
      }

      { courses.map((course) => (
        <Card
          key={course.uuid}
          image={process.env.REACT_APP_CDN_URL + course.cover_source}
          title={course.title}
          link={`/kurse/${course.uuid}`}
          description={course.description} />
      ))}

    </div>
  )
}

const mapStateToProps = (state) => {
  return ({
    courses: state.courses.data.filter(c => c.access_since !== null),
  });
}

export default connect(mapStateToProps)(CoursesOverview);
