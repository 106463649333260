import React from 'react';
import Button from 'components/style/Button';
import Placeholder from 'components/style/Placeholder';

import { Link } from 'react-router-dom';

const PlaceholderCard = () => {
  return (
    <div className="bg-white w-full rounded-lg overflow-hidden">

      <div
        className="bg-gray-300 animate-pulse bg-center"
        style={{ height: '250px' }} />

      <div className="p-4">
        <h4 className="mb-2"><Placeholder length={12} /></h4>
        <Placeholder length={40}/>
        <Placeholder length={35}/>
      </div>
    </div>
  );
}

export default PlaceholderCard;
