// AUTH
export const AUTH_REQUESTED = 'AUTH_REQUESTED';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_DISMISS = 'AUTH_DISMISS';

// USERS
export const USERS_SUCCESS = 'USERS_SUCCESS';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_DELETE = 'USER_DELETE';

// PROFILES
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_UPDATE_REQUEST = 'PROFILE_UPDATE_REQUEST';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_FAILURE = 'PROFILE_UPDATE_FAILURE';
export const PROFILE_DELETE_SUCCESS = 'PROFILE_DELETE_SUCCESS';

// COURSES
export const COURSES_SUCCESS = 'COURSES_SUCCESS';
export const MODULES_SUCCESS = 'MODULES_SUCCESS';

// PRODUCTS
export const PRODUCTS_SUCCESS = 'PRODUCTS_SUCCESS';

// MENU
export const TOGGLE_MENU = 'TOGGLE_MENU';
