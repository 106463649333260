import React from 'react';

const Placeholder = ({ length, text }) => {
  if (!!text) {
    return text;
  }

  return (
    <div
      style={{ height: '1em', width: `${length / 2 }em` }}
      className={`inline-block bg-gray-400 animate-pulse`} />

  );
}

export default Placeholder;
