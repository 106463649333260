import { combineReducers } from 'redux';
import auth from './auth';
import users from './users';
import profile from './profile';
import courses from './courses';
import products from './products';
import menu from './menu';

export default combineReducers({
  auth,
  users,
  profile,
  courses,
  products,
  menu,
});
