import React from 'react';
import PropTypes from 'prop-types';

export default class AudioPlayer extends React.Component {
  render() {
    return (
      <div>
        <audio controls className="w-full">
          <source src={this.props.audio.url} />
        </audio>
        <p className="my-4">{this.props.audio.title}</p>
      </div>
    )
  }
}
