import React from 'react'
import { connect } from 'react-redux';
import { loadCourse, loadModules } from 'actions/courses';

import ModulesOverview from 'components/ModulesOverview';

class Course extends React.Component {
  render() {
    const { course, modules } = this.props;

    return (
      <div className="container flex flex-col m-auto px-5">

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

          <img
            className="rounded-lg"
            src={process.env.REACT_APP_CDN_URL + course.cover_source} />

          <div>
            <h1 className="text-bold pb-5 text-2xl xl:text-3xl 2xl:text-4xl">
              { course.title }
            </h1>

            <div className="py-2 rounded leading-normal">
              <div className="text-base">
                { course.description }
              </div>
            </div>
          </div>
        </div>

        <div className="my-5">
          <h2 className="text-xl xl:text-2xl 2xl:text-3xl mb-2">Module</h2>

          <ModulesOverview
            course={course}
            modules={course.modules || []} />

        </div>

      </div>
    );
  }
}

class CourseWrapper extends React.Component {
  componentDidMount() {
    const { dispatch, course, match } = this.props;

    if (course) {
      return
    }

    dispatch(loadCourse(match.params.id));
  }

  render() {
    const { dispatch, course } = this.props;

    if (course && course.modules === undefined) {
      dispatch(loadModules(course.uuid));
    }

    return (
      <React.Fragment>
        {
          course ? <Course course={course} /> : <div>Lade</div>
        }
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    course: state.courses.data.find(c => c.uuid === ownProps.match.params.id)
  };
}

export default connect(mapStateToProps)(CourseWrapper);
