import React from 'react';

import qs from 'qs';
import RequestForm from './RequestForm';
import ResetForm from './ResetForm';

class Reset extends React.Component {

  // Fetch the token from the location.
  fetchToken() {
    const params = qs.parse(
      this.props.location.search,
      { ignoreQueryPrefix: true }
    );

    return params['token'];
  }

  render() {
    const token = this.fetchToken()

    return(
      <div className="container m-auto flex justify-around items-center flex-col md:flex-row">
        { token === undefined ?
            <RequestForm /> :
            <ResetForm token={token} />
        }
      </div>
    );
  }
}

export default Reset;
