import React from 'react';
import PropTypes from 'prop-types';

const TextInput = ({ type, onChange, value, placeholder, className, name }) => {
  return (
    <input
      type={type ?? 'text'}
      className={`border outline-none py-3 px-5 w-full rounded ${className}`}
      value={value ?? ""}
      name={name ?? ""}
      placeholder={placeholder ?? ""}
      onChange={onChange} />
  );
}

export default TextInput;
