import React from 'react'
import Modal from '../../components/Modal';
import { connect } from 'react-redux';
import { loadProduct } from '../../actions/products';

class BuyButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDialog: false
    }

    this.createPayment = this.createPayment.bind(this);
  }

  componentDidMount() {
    const { dispatch, course } = this.props;

    dispatch(loadProduct(course.product_id));
  }

  createPayment() {
    // Generate payload.
    const payload = { product_id: this.props.course.product_id }

    // Create payment and redirect to payment page.
    window.paymentsApi.post(`/v1/payments`, payload)
      .then((response) => {
        window.location.replace(response.data.checkout_url);
      })
      .catch((response) => console.log(response))
  }

  render() {
    const { product, course } = this.props;

    if (!product) {
      return (
        <button
          onClick={() => this.setState({ showDialog: true })}
          className="bg-blue-500 text-sm text-white leading-6 inline-block
                     rounded px-4 py-1 font-bold">
          Lade Kurs ...
        </button>
      )
    }

    return (
      <>
        <button
          onClick={() => this.setState({ showDialog: true })}
          className="bg-blue-500 text-sm text-white leading-6 inline-block
                     rounded px-4 py-1 font-bold">
          Kurs freischalten
        </button>

        <Modal
          title={`"${course.title}" freischalten`}
          onClose={() => this.setState({ showDialog: false })  }
          onSuccess={this.createPayment}
          successText="Jetzt kaufen"
          isOpen={this.state.showDialog}>
          <div>
            { product.name }
          </div>

          <div dangerouslySetInnerHTML={{ __html: product.description }} />
        </Modal>
      </>
    )
  }
}

function mapStateToProps(state, ownProps) {
  return {
    product: state.products.data.find((p) => p.uuid === ownProps.course.product_id)
  }
}

export default connect(mapStateToProps)(BuyButton);
