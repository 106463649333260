import React from 'react';

import Logo from 'images/logo2.png'

class Spinner extends React.Component {
  render() {
    return(
      <div
        style={{ minHeight: "80vh" }}
        className="flex flex-col items-center justify-center">
        <img height={75} width={75} src={Logo} />
      </div>
    );
  }
}

export default Spinner;
