import {
  USERS_SUCCESS,
  USER_SUCCESS,
  USER_DELETE,
} from '../constants/actions';
import { unionBy } from 'lodash';

const initialState = {
  isFetching: false,
  hasFetched: false,
  data: [],
}

export default(state = initialState, action) => {
  switch(action.type) {
    case USERS_SUCCESS:
      return {
        ...state,
        data: unionBy(state.data, action.data, u => u.id)
      }

    case USER_SUCCESS:
      return {
        ...state,
        data: state.data.map(u => {
          if (u.id === action.data.id) {
            return action.data;
          }

          return u;
        })
      }

    case USER_DELETE:
      return {
        ...state,
        data: state.data.filter(u => u.id !== action.id)
      }

    default:
      return {
        ...state
      }
  }
}
