import {
  PRODUCTS_SUCCESS,
} from '../constants/actions';
import { unionBy } from 'lodash';

const initialState = {
  isFetching: false,
  isUpdating: false,
  hasFetched: false,
  data: [],
}

export default(state = initialState, action) => {
  switch(action.type) {
    case PRODUCTS_SUCCESS:
      return {
        ...state,
        data: unionBy(state.data, [action.data], c => c.uuid)
      }

    default:
      return {
        ...state
      }
  }
}
