import {
  PRODUCTS_SUCCESS,
} from '../constants/actions';

export function loadProduct(uuid) {
  return dispatch => {
    // Call the product api to find the product.
    window.paymentsApi.get(`/v1/products/${uuid}`)
      .then(function(response) {
        dispatch({ type: PRODUCTS_SUCCESS, data: response.data });
      })
      .catch(function(response) {
        // TODO
      });
  }
}
