import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Bounce from 'react-reveal/Bounce';

import { toggleMenu } from 'actions/menu';
import Logo from 'images/logo.png';
import Button from 'components/style/Button';
import { logout } from 'actions/auth';

const Menu = ({ dispatch, isToggled }) => {
  const onClick = () => {
    dispatch(logout());
  }

  return (
    <Bounce top when={isToggled}>
      <div
        onClick={() => dispatch(toggleMenu())}
        className={`fixed inset-0 ${isToggled ? 'flex' : 'hidden'}
        flex-col z-20 bg-background justify-center items-center`}>

        <img src={Logo} width={120} className="mb-10" />

        <ul className="flex font-headline flex-col justify-center items-center text-3xl">
          <li><Link to="/">Übersicht</Link></li>
          <li><Link to="/einstellungen">Einstellungen</Link></li>
          <li className="mt-2">
            <button onClick={onClick} className="text-red-600 text-2xl">
              Abmelden
            </button>
          </li>
        </ul>

      </div>
    </Bounce>
  )
}

const mapStateToProps = (state) => {
  return ({
    isToggled: state.menu.isToggled
  });
}

export default connect(mapStateToProps)(Menu);
