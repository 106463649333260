import {
  COURSES_SUCCESS,
  MODULES_SUCCESS,
} from '../constants/actions';

const initialState = {
  isFetching: false,
  isUpdating: false,
  hasFetched: false,
  data: [],
}

export default(state = initialState, action) => {
  switch(action.type) {
    case COURSES_SUCCESS:
      return {
        ...state,
        data: action.data,
      }

    case MODULES_SUCCESS:
      return {
        ...state,
        data: state.data.map((c) => {
          if (c.uuid === action.course_id) {
            return Object.assign({}, c, { modules: action.data });
          }

          return c;
        })
      }

    default:
      return {
        ...state
      }
  }
}
