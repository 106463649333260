import React from 'react'
import { connect } from 'react-redux';
import PrivateApp from './private';
import PublicApp from './public';
import Spinner from './spinner';

import {
  AUTH_SUCCESS, AUTH_DISMISS, AUTH_LOGOUT
} from '../../constants/actions';

class App extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;

    //Mark the app as loaded.
    document.body.classList.toggle('loaded', true)

    // Get all tokens from localStorage.
    const refreshToken = localStorage.getItem('refreshToken');

    // If we have a refresh token, continue.
    if (refreshToken) {
      return dispatch({ type: AUTH_SUCCESS })
    }

    dispatch({ type: AUTH_LOGOUT });
  }

  render() {
    const { isAuthenticated, isAuthenticating } = this.props.auth;

    if (isAuthenticating) {
      return(<Spinner />);
    }

    return(
      <React.Fragment>
        { isAuthenticated ? <PrivateApp /> : <PublicApp /> }
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    router: state.router
  };
}

export default connect(mapStateToProps)(App);
