import React from 'react';
import Octicon, { ThreeBars, X } from '@githubprimer/octicons-react';
import { connect } from 'react-redux';

import { toggleMenu } from 'actions/menu';

const MenuButton = ({ dispatch, isToggled }) => {
  const onClick = () => {
    dispatch(toggleMenu());
  }

  return (
    <button onClick={onClick} className="z-50 outline-none">
      <Octicon icon={isToggled ? X : ThreeBars } size="medium" />
    </button>
  )
}

const mapStateToProps = (state) => {
  return ({
    isToggled: state.menu.isToggled
  });
}

export default connect(mapStateToProps)(MenuButton);
