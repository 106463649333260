import {
  PROFILE_SUCCESS,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_FAILURE,
} from '../constants/actions';

const initialState = {
  isFetching: false,
  isUpdating: false,
  hasFetched: false,
  data: {},
}

export default(state = initialState, action) => {
  switch(action.type) {
    case PROFILE_SUCCESS:
      return {
        ...state,
        data: action.data,
      }

    case PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        isUpdating: true,
      }

    case PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        data: action.data,
      }

    case PROFILE_UPDATE_FAILURE: {
      return {
        ...state,
        isUpdating: false,
      }
    }

    default:
      return {
        ...state
      }
  }
}
