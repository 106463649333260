import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ onClick, title, primary }) => {
  return (
    <button
      onClick={onClick}
      className={`bg-${ primary ? 'primary' : 'white'} hover:bg-opacity-75
                 font-bold py-3 px-4 rounded-full focus:outline-none
                 focus:shadow-outline w-full`}>

      { title }

    </button>
  );
}

export default Button;
