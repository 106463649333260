import React from 'react';
import PropTypes from 'prop-types';

export default class VideoPlayer extends React.Component {
  render() {
    return (
      <div>
        <iframe
          width="100%"
          height="250px"
          src={this.props.video.source}
          frameBorder="0"
          title={this.props.video.title}
          allowFullScreen
          mozallowfullscreen="true"
          webkitallowfullscreen="true" />
        { this.props.withTitle &&
        <div className="text-sm font-bold pt-1">
          { this.props.video.title}
        </div>
        }
      </div>
    )
  }
}

VideoPlayer.propTypes = {
  video: PropTypes.object.isRequired
};
