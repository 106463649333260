import {
  COURSES_SUCCESS,
  MODULES_SUCCESS,
} from '../constants/actions';

export function loadCourses() {
  return dispatch => {
    // Call the club api to find the users.
    window.coursesApi.get(`/v1/courses`)
      .then(function(response) {
        dispatch({ type: COURSES_SUCCESS, data: response.data });
      })
      .catch(function(response) {
        // TODO
      });
  }
}

export function loadModules(course_id) {
  return dispatch => {
    // Call the club api to find the users.
    window.coursesApi.get(`/v1/modules?course_id=${course_id}`)
      .then(function(response) {
        dispatch({
          type: MODULES_SUCCESS,
          data: response.data,
          course_id
        });
      })
      .catch(function(response) {
        // TODO
      });
  }
}

export function loadCourse(id) {
  return dispatch => {
    // Call the club api to find the users.
    window.coursesApi.get(`/v1/courses/${id}`)
      .then(function(response) {
        dispatch({ type: COURSES_SUCCESS, data: [response.data] });
      })
      .catch(function(response) {
        // TODO
      });
  }
}
