import axios from 'axios';

export default () => {
  window.usersApi = axios.create({
    baseURL: process.env.REACT_APP_USERS_API_URL,
  });

  window.coursesApi = axios.create({
    baseURL: process.env.REACT_APP_COURSES_API_URL,
  });

  window.paymentsApi = axios.create({
    baseURL: process.env.REACT_APP_PAYMENTS_API_URL,
  });

  // Set basic axios defaults.
  axios.defaults.withCredentials = true;

  [window.usersApi, window.coursesApi, window.paymentsApi].forEach((api) => {
    // Set axios request interceptors
    api.interceptors.request.use(config => {
      const token = localStorage.getItem('accessToken');

      if ( token != null ) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    }, (error) => {
      return Promise.reject(error);
    });

    // Set axios response interceptors
    api.interceptors.response.use(
      // On success
      (response) => {
        return response;
      },
      // On failure
      async (error) => {
        const req = error.config;

        if (error.response && error.response.status === 401 && !req._retry) {
          req._retry = true

          try {
            await refreshToken();
            return api(req);
          } catch {
            // If refresh token could not be fetched - logout.
            logout();
          }
        }

        return Promise.reject();
      }
    );
  });
};

// Log the user out of the system.
function logout() {
  localStorage.clear();
  window.location.href = '/';
}

// Request a new jwt bundle.
async function refreshToken() {
  const refresh_token = localStorage.getItem("refreshToken")
  const refreshURL = `${process.env.REACT_APP_USERS_API_URL}/v1/refresh`

  try {
    const response = await axios.post(refreshURL, { refresh_token });
    localStorage.setItem('accessToken', response.data.access_token);
    localStorage.setItem('refreshToken', response.data.refresh_token);
  } catch(error) {
    return Promise.reject();
  }
}
