import { TOGGLE_MENU, } from '../constants/actions';

const initialState = {
  isToggled: false,
}

export default(state = initialState, action) => {
  switch(action.type) {
    case TOGGLE_MENU:
      return {
        isToggled: !state.isToggled
      }

    default:
      return {
        ...state
      }
  }
}
