import React from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { loadCourses } from '../../actions/courses';
import moment from 'moment';

import BuyButton from './BuyButton';

class Courses extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(loadCourses());
  }

  render() {
    return (
      <div className="container flex flex-col m-auto">
        <h1 className="text-bold py-5 text-3xl xl:text-4xl 2xl:text-5xl">
          Kurse
        </h1>
        <div className="bg-gray-200 p-5 rounded leading-normal">
          <h2 className="font-bold mb-4 text-xl">
            Willkommen in deinem Kurs-Bereich.
          </h2>
          <div className="text-base">
            Im Folgenden findest du deine Kurse bzw. deinen Ausbildungskurs.
          </div>
        </div>

        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 mt-4">
          { this.props.courses.data.map((c) => (
            <div className="border rounded mb-4 flex flex-col justify-between" key={c.uuid}>
              <div>
                <div
                  style={{
                    height: '250px',
                    backgroundImage: c.cover_source ? `url("${process.env.REACT_APP_CDN_URL}${c.cover_source}")` : 'none'
                  }}
                  className="bg-gray-300 bg-cover"></div>

                <div className="p-3">
                  <h3 className="font-bold mb-2">{ c.title }</h3>

                  <p className="text-sm">
                    { c.description }
                  </p>
                </div>
              </div>

              <div className="px-3 pb-4 flex flex-col md:flex-row md:justify-between md:items-center">
                { c.access_since ?
                  <>
                    <Link
                      to={`/kurse/${c.uuid}`}
                      className="bg-blue-500 text-sm text-white leading-6 inline-block
                                 rounded px-4 py-1 font-bold text-center">
                      Zum Kurs &rarr;
                    </Link>
                    <p className="text-xs italic mt-2 md:ml-2 text-gray-600">
                      Zugriff seit dem {moment(c.access_since).format('DD.MM.YYYY')}
                    </p>
                  </> :
                  <BuyButton course={c} />
                }
              </div>
            </div>
          )) }
        </div>

        {
          this.props.courses.data.length === 0 &&
            <div>
              Derzeit sind noch keine Kurse freigeschaltet. Kontaktiere
              den <a href="mailto:kontakt@reifam.org" className="underline">Support</a>, falls du
              Fragen hast.
            </div>
        }

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    courses: state.courses,
  };
}

export default connect(mapStateToProps)(Courses);
