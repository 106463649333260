import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { authByPassword } from 'actions/auth';

import Button from 'components/style/Button';
import TextInput from 'components/style/TextInput';

const WrongPasswordAlert = () => (
  <div className="text-xs text-red-600">
    Das hat leider nicht geklappt. Überprüfe dein Passwort oder deine
    E-Mail-Adresse. <Link className="font-bold" to="/reset">
    Support kontaktieren?</Link>
  </div>
);

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      error: null
    };

    this.authenticate = this.authenticate.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value })
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value })
  }

  authenticate(event) {
    event.preventDefault();

    const { dispatch } = this.props;

    const email = this.state.email;
    const password = this.state.password;

    dispatch(authByPassword(email, password));
  }

  render() {
    const { auth } = this.props;

    return (
      <div className="w-full">

        <form className="flex flex-col">

          <div>
            <TextInput
              className="border-b-0"
              placeholder="E-Mail-Adresse"
              onChange={this.handleEmailChange}
              value={this.state.email} />
          </div>

          <div className="mb-3">
            <TextInput
              type="password"
              placeholder="Passwort"
              onChange={this.handlePasswordChange}
              value={this.state.password} />
          </div>

          <Button
            primary
            onClick={this.authenticate}
            title="Anmelden" />

        </form>

        <div className="py-3">
          { auth.isFailed && <WrongPasswordAlert /> }
        </div>

        <hr />

        <div className="flex text-sm font-bold justify-center items-center py-3">
          <Link to="/reset">Passwort vergessen?</Link>
        </div>

      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}

export default connect(mapStateToProps)(LoginForm);
